import React from "react"
import { Link } from "gatsby-plugin-intl"

const KfgThumbnail = ({ kfg }) => {
  return (
    <>
      {kfg.edges.map(({ node }, index) => (
        <div
          key={index}
          className="flex justify-center image bg-white rounded border border-gray-400 hover:shadow"
        >
          <Link to={"/" + node.cat + "/download?q=" + node.name}>
            <img
              src={"/images/" + node.cat + "/" + node.name + ".svg"}
              alt={node.tag}
              loading="lazy"
              className="p-6 h-32 md:h-48"
            ></img>
          </Link>
        </div>
      ))}
    </>
  )
}

export default KfgThumbnail
