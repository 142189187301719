import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

const KfgCategories = () => {
  const intl = useIntl()
  return (
    <div className="flex flex-wrap">
      <div className="image bg-white rounded border border-gray-400 hover:shadow mb-1 mr-2 text-sm px-2 py-1">
        <Link
          to="/kung-fu-geek/"
          className=""
          activeClassName="font-bold underline"
        >
          # {intl.formatMessage({ id: "kfg.all" })}
        </Link>
      </div>
      <div className="image bg-white rounded border border-gray-400 hover:shadow mb-1 mr-2 text-sm px-2 py-1">
        <Link
          to="/kung-fu-geek/general"
          className=""
          activeClassName="font-bold underline"
        >
          # {intl.formatMessage({ id: "kfg.general" })}
        </Link>
      </div>
      <div className="image bg-white rounded border border-gray-400 hover:shadow mb-1 mr-2 text-sm px-2 py-1">
        <Link
          to="/kung-fu-geek/not-advised"
          className=""
          activeClassName="font-bold underline"
        >
          # {intl.formatMessage({ id: "kfg.notAdviced" })}
        </Link>
      </div>
      <div className="image bg-white rounded border border-gray-400 hover:shadow mb-1 mr-2 text-sm px-2 py-1">
        <Link
          to="/kung-fu-geek/remote-work"
          className=""
          activeClassName="font-bold underline"
        >
          # {intl.formatMessage({ id: "kfg.remoteWork" })}
        </Link>
      </div>
      <div className="image bg-white rounded border border-gray-400 hover:shadow mb-1 mr-2 text-sm px-2 py-1">
        <Link
          to="/kung-fu-geek/job-title"
          className=""
          activeClassName="font-bold underline"
        >
          # {intl.formatMessage({ id: "kfg.jobTitle" })}
        </Link>
      </div>
    </div>
  )
}

export default KfgCategories
